<template>
  <div class="row order_global">
    <!-- <span class="the-icon text-center"><i id="plus_icn" class="flaticon2-plus icon-2x text-primary"></i></span>
     -->
    <button
      @click="showNewOrderButtons()"
      v-html="
        getIconByKey('icons.waybill.waybill_entry', {
          class: 'the-icon w-65px h-65px d-inline-block object-fill cursor-pointer',
        })
      "
    ></button>
    <div class="new-order" v-if="toggleNewOrderButtons === true">
      <span><button @click="createNewOrder(2)">Koleksiyon Numunesinden</button></span>
      <span><button @click="createNewOrder(1)">Özgün Sipariş</button></span>
    </div>
    <b-modal
      ref="open_to_settings_table"
      id="open_to_settings_table"
      hide-header
      hide-header-close
      size="sm"
      scrollable
    >
      <div class="row mx-0 py-7">
        <div
          class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold"
        >
          <div class="col-6 text-center">{{ $t("general.colum_name") }}</div>
          <div class="col-6 text-center">{{ $t("general.add") }}</div>
        </div>
        <div class="col-12 mx-0 py-2 text-xl">
          <draggable
            class="list-group py-4"
            tag="ul"
            v-model="fieldsToViewEdit"
            v-bind="dragOptions"
            :move="onMove"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group type="transition" :name="'flip-list'">
              <div
                class="col-12 list-group-item w-300px d-flex justify-content-between"
                v-for="element in fieldsToViewEdit"
                :key="element.sort"
              >
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input
                    type="checkbox"
                    style="font-size: 10px"
                    v-model="element.type_status"
                /></span>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            @click="closeModal"
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
          >
            {{ $t("general.give_up").toUpperCase() }}
          </button>
          <button
            @click="onSubmitToSaveSettingTable"
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
          >
            {{ $t("general.save") }}
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="display-pdf-modal"
      id="display-pdf-modal"
      hide-header
      hide-header-close
      size="xl"
      scrollable
    >
      <div class="row mx-0 py-7">
        <div class="col-12 mx-0 py-2 text-xl">
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            style="display: inline-block; width: 100%"
          ></pdf>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            @click="closeModal('display-pdf-modal')"
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
          >
            {{ $t("general.close") }}
          </button>

          <button
            @click="downloadPdf"
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
          >
            {{ $t("order.download") }}
          </button>
        </div>
      </template>
    </b-modal>

    <!--Order Top-->
    <div class="col-md-12" style="background-color: #fff !important">
      <div
        id="filter-card order-filter"
        class="row py-3 px-1"
        style="
          background-color: #fff !important;
          box-shadow: none !important;
          border: none !important;
        "
      >
        <div class="col-lg-12 col-xl-12 px-0" style="background-color: #fff !important">
          <form
            @submit.prevent="filterResult()"
            style="background-color: #fff !important"
          >
            <div class="py-0 my-0 mx-0 try_div" v-if="filters">
              <div class="my-1 row gs" style="background-color: #fff !important">
                <input-filter
                  @keypress.enter.native.prevent="filterResult()"
                  :helper-text="$t('general.search')"
                  title="general.search"
                  placeholder="Ara"
                  :model.sync="filters.search"
                ></input-filter>
                <span
                  v-html="
                    getIconByKey('icons.meeting.search', {
                      class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
                    })
                  "
                  @click="filterResult()"
                ></span>
              </div>
              <div class="col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0 gsd-ged">
                <div class="col-lg-6 col-md-6 col-sm-6 my-2 my-md-0 gsd" id="gsd">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    title="general.start_date"
                    :model.sync="filters.start_date"
                    name="start_time"
                    readonly="readonly"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 my-2 my-md-0 ged">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    title="general.end_date"
                    :model.sync="filters.end_date"
                    name="end_date"
                    readonly="readonly"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row hide_content"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    type="button"
                    @click.prevent="filterResult()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    type="button"
                    @click="resetFilters()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    type="button"
                    @click="exportData()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.export_to_list") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <context-menu :display="showContextMenu" ref="menu">
      <ul>
        <li @click="openNewTab">{{ $t("order.open_new_tab") }}</li>
      </ul>
    </context-menu>
    <div class="col-md-12 order_table" @contextmenu.prevent="false">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row order_menu" v-if="filters">
            <div
              @click="
                filters.order_status_id = 49;
                order_status_id = 49;
              "
              :class="filters.order_status_id === 49 ? 'nav_outlook' : ''"
              class="cursor-pointer order_menu_item"
            >
              {{ $t("order.stock_orders") }}
            </div>
            <div
              @click="
                filters.order_status_id = 5;
                order_status_id = 5;
              "
              :class="filters.order_status_id === 5 ? 'nav_outlook' : ''"
              class="cursor-pointer order_menu_item"
            >
              {{ $t("order.uploaded_orders") }}
            </div>
            <div
              @click="
                filters.order_status_id = 6;
                order_status_id = 6;
              "
              :class="filters.order_status_id === 6 ? 'nav_outlook' : ''"
              class="cursor-pointer order_menu_item"
            >
              {{ $t("order.canceled_orders") }}
            </div>
            <div
              @click="
                filters.order_status_id = 4;
                order_status_id = 4;
              "
              :class="filters.order_status_id === 4 ? 'nav_outlook' : ''"
              class="cursor-pointer order_menu_item"
            >
              {{ $t("order.open_orders") }}
            </div>
            <div
              @click="
                filters.order_status_id = null;
                order_status_id = null;
              "
              :class="filters.order_status_id === null ? 'nav_outlook' : ''"
              class="cursor-pointer order_menu_item"
            >
              {{ $t("order.all") }}
            </div>
          </div>
          <div
            class="fixed"
            style="
              display: none !important;
              position: fixed !important;
              top: 290px;
              right: 15px;
            "
          >
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span
                v-html="
                  getIconByKey('icons.waybill.edit', {
                    class: 'w-40px h-40px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
          </div>
          <div class="row hide_content">
            <div class="col-12">
              <span class="h4 float-right mr-4">
                <b>{{ $t("order.summary_count") }} </b>: {{ total }} /
                <b>{{ $t("order.loaded_amount") }} </b>: {{ export_amount }} /
                <b>{{ $t("order.summary_sum") }}:</b> {{ amount }}</span
              >
            </div>
          </div>
          <!--Cards Start-->
          <div class="row" v-if="+toggleTheList == 1">
            <ul class="order_cards">
              <li
                v-for="(item, index) in formattedItems"
                :key="index"
                @click="onRowClicked(item, index)"
              >
                <b-badge pill class="card_badge_table">{{ item.order_number }}</b-badge>
                <img v-lazy="item.normal_image" class="card_image" />
              </li>
            </ul>
          </div>
          <!--Cards List View Start-->
          <div class="row" v-else>
            <ul class="list_order_cards">
              <li
                v-for="(item, index) in formattedItems"
                :key="index"
                @click="onRowClicked(item, index)"
              >
                <b-badge pill variant="primary" class="card_badge">{{
                  item.order_number
                }}</b-badge>
                <img v-lazy="item.normal_image" class="card_image" />
                <div class="list_order_card_footer">
                  <span class="detail_item">{{
                    capitalizeStr(item.customer_representative)
                  }}</span>
                  <span class="detail_item">{{ capitalizeStr(item.customer) }}</span>
                  <span class="detail_item">{{ item.amount }}</span>
                  <span class="detail_item">{{ item.model }}</span>
                </div>
                <p>{{ item.description }}</p>
              </li>
            </ul>
          </div>
          <infinite-loading @infinite="onHitBottom" :identifier="infiniteId">
            <span slot="spinner"></span>
            <span slot="no-more">
              <h4 v-if="filters && filters.page > 2">
                {{ $t("general.no_more_records") }}
              </h4>
            </span>
          </infinite-loading>
          <div class="row">
            <div class="col-12 hide_content">
              <span class="h4 float-right mr-4">
                <b>{{ $t("order.summary_count") }} </b>: {{ total }} /
                <b>{{ $t("order.loaded_amount") }} </b>: {{ export_amount }} /
                <b>{{ $t("order.summary_sum") }}:</b> {{ amount }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import pdf from "vue-pdf";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";

import {
  EXPORT,
  FILTER,
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  ITEMS,
  LOADING,
  SET_FILTER,
  SET_ITEMS,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/order/order.module";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import {
  ORDER_INDEX_START_DATE_STORAGE_NAME,
  ORDER_INDEX_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";
import * as _ from "lodash";
import draggable from "vuedraggable";

export default {
  name: "OrderIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    draggable,
    pdf,
    ContextMenu,
  },
  beforeRouteLeave(to, from, next) {
    //check here
    this.config.content.width = "fixed";
    next();
  },

  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
      layoutConfig: ["layoutConfig"],
    }),
    config() {
      return this.layoutConfig();
    },

    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedItems() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];

      this.amount = 0;
      this.export_amount = 0;
      this.total = 0;

      this.total = Object.keys(this.items.data).length;
      let temp = [];
      for (let i = 0; i < this.items.data.length; i++) {
        let tempCurrent = this.items.data[i];

        let manufacturer = null;
        let export_date = null;
        let export_date_2 = null;
        let order_fabric = null;
        let order_fabric_1 = null;
        let start_date = null;
        let end_date = null;

        if (tempCurrent.hasOwnProperty("order_expenses")) {
          tempCurrent.order_expenses.forEach((item) => {
            if (
              +item.expense_id === 2 &&
              item.hasOwnProperty("order_expense_details") &&
              item.order_expense_details.length &&
              item.order_expense_details[0].hasOwnProperty("supplier_company")
            ) {
              let manufacturers = [];
              item.order_expense_details.forEach((supplierCompany) => {
                manufacturers.push(supplierCompany.supplier_company.name);
              });

              manufacturers = _.uniq(manufacturers);
              manufacturer = manufacturers.join(" - ");
            }
          });
        }

        if (
          tempCurrent.hasOwnProperty("order_export_dates") &&
          tempCurrent.order_export_dates.length
        ) {
          export_date = moment(
            tempCurrent.order_export_dates[0].export_date,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
        }

        if (
          tempCurrent.hasOwnProperty("order_export_dates") &&
          tempCurrent.order_export_dates.length > 1
        ) {
          export_date_2 = moment(
            tempCurrent.order_export_dates[1].export_date,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
        }

        if (
          tempCurrent.hasOwnProperty("order_fabrics") &&
          tempCurrent.order_fabrics.length
        ) {
          start_date = moment(
            tempCurrent.order_fabrics[0].deadline_start,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
          end_date = moment(
            tempCurrent.order_fabrics[0].deadline_end,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
          order_fabric = start_date + " - " + end_date;
        }

        if (
          tempCurrent.hasOwnProperty("order_fabrics") &&
          tempCurrent.order_fabrics.length > 1
        ) {
          start_date = moment(
            tempCurrent.order_fabrics[1].deadline_start,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
          end_date = moment(
            tempCurrent.order_fabrics[1].deadline_end,
            LARAVEL_DATE_FORMAT
          ).format(MOMENT_SYSTEM_DATE_FORMAT);
          order_fabric_1 = start_date + " - " + end_date;
        }

        this.amount += tempCurrent.amount;
        this.export_amount += Number(tempCurrent.export_amount);

        temp.push({
          id: tempCurrent.id,
          normal_image: tempCurrent.image_link,
          order_date: moment(tempCurrent.order_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
          order_number: tempCurrent.order_number,
          customer: tempCurrent.company.name,
          model: tempCurrent.model,
          amount: tempCurrent.amount,
          export_amount: tempCurrent.export_amount,
          customer_representative: tempCurrent.user ? tempCurrent.user.name : "-",
          description: tempCurrent.description,
          sample_id: tempCurrent.sample_id,
          po_number: tempCurrent.po_number,
          manufacturer: manufacturer,
          export_date_1: export_date,
          export_date_2: export_date_2,
          order_fabric: order_fabric,
          order_fabric_1: order_fabric_1,
          download_link: tempCurrent.pdf_link,
          updated_at: moment(tempCurrent.updated_at, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
        });
      }

      return temp;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          let options = [];
          if (key === "manufacturer") {
            for (const [key, value] of Object.entries(this.supplierCompanies)) {
              options.push({
                value: key,
                text: value,
              });
            }
          }

          fields.push({
            key: key,
            value: this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : "",
            label: this.$t(value.name),
            sortable: value.sortable,
            sort: value.sort,
            class: "text-center border border-top-0 border-left-0",
            tdClass: "cursor-pointer",
            searchable: value.searchable,
            searchable_type: value.searchable_type,
            searchable_options: options,
          });
        }
      }
      return _.sortBy(fields, ["sort"]);
    },
  },
  data() {
    return {
      toggleNewOrderButtons: false,
      toggleTheList: 1,
      showContextMenu: false,
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      fieldsToViewEdit: [],
      sort: "desc",
      order: "id",
      pdfs: [],
      src: null,
      numPages: undefined,
      currentPDF: {
        blob: null,
      },

      search: null,
      order_status_id: 4,
      supplierCompanies: [],
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        },
      ],
      orderUrl: "api/orders",
      newTabRoute: null,
      total: 0,
      export_amount: 0,
      amount: 0,
    };
  },
  methods: {
    showNewOrderButtons() {
      this.toggleNewOrderButtons = !this.toggleNewOrderButtons;
    },
    createNewOrder(type) {
      this.$router.push({ name: "order.create", params: { type: type } });
    },
    capitalizeStr(str) {
      const modifiedStr = str.toLowerCase();
      let arr = modifiedStr.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const strCapitalized = arr.join(" ");
      return strCapitalized;
    },
    onRightClick(item, index, e) {
      this.$refs.menu.open(e);
      this.newTabRoute = this.$router.resolve({
        name: "order.edit",
        params: {
          id: this.formattedItems[index].id,
          type: this.formattedItems[index].sample_id ? 2 : 1,
        },
      });
    },
    openNewTab() {
      window.open(this.newTabRoute.href, "_blank");
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    downloadPdf() {
      this.openInNewTab(this.currentPDF.download_link);
    },
    onDownloadButtonClick(item) {
      let self = this;
      this.$store.commit(SET_LOADING, true);
      this.currentPDF = item;
      this.src = pdf.createLoadingTask(this.currentPDF.pdf_link);

      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });

      setTimeout(() => {
        self.$store.commit(SET_LOADING, false);
        self.$refs["display-pdf-modal"].show();
      }, 2000);
    },
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    getTableItem() {
      let self = this;
      self.fieldsToViewEdit = [];
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/model_table",
          filters: {
            model_name: "order",
          },
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            for (let i = 0; i < result.data.length; i++) {
              self.modelTableItem = result.data;
              self.modelTableItem[i].table_fields = JSON.parse(
                result.data[i].table_fields
              );
            }
            for (const [key, value] of Object.entries(
              self.modelTableItem[0].table_fields
            )) {
              self.fieldsToViewEdit.push({
                name: this.$t(value.name),
                sort: value.sort,
                type: value.type,
                sortable: value.sortable,
                type_status: value.status,
                key: key,
                searchable: !!value.searchable,
                searchable_type: value.searchable_type,
                searchable_options: value.searchable_options,
              });
              self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ["sort"]);
            }
          }
        });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort(
        (one, two) => {
          return one.sort - two.sort;
        }
      );
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.status) && !draggedElement.status;
    },
    openPopToSettingTable() {
      this.$refs["open_to_settings_table"].show();
    },
    closeModal(name = "open_to_settings_table") {
      this.$refs[name].hide();
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};

      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sortable: this.fieldsToViewEdit[i].sortable,
          sort: i,
          searchable: !!this.fieldsToViewEdit[i].searchable,
          searchable_type: this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name: "order",
        table_fields: json,
      };
      let payload = {
        url: "api/model_table/" + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      };
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((result) => {
        if (result.status) {
          this.$modal.hide("open_to_settings_table");
          self.sweetAlertSuccess(self.$t("general.successfully_created"));
          this.fieldsToViewEdit = [];
          this.getTableItem();
        } else {
          self.sweetAlertError(self.$t("general.internal_error"));
        }
      });
    },
    filterResult() {
      localStorage.setItem(
        ORDER_INDEX_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        ORDER_INDEX_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom(null);
    },
    onHitBottom($state = null) {
      let self = this;
      if (!this.isUserGranted("Order", ["viewAny"])) {
        return false;
      }
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: this.orderUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          if (result.status) {
            if ($state) {
              $state.loaded();
            }
          } else {
            if ("end" in result) {
              if ($state) {
                $state.complete();
              }
            }
          }
        });
    },
    resetFilters() {
      this.amount = 0;
      this.export_amount = 0;
      this.total = 0;
      this.setFilter({
        start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        search: null,
        order_status_id: 4,
        order: "",
        sort: "",
        page: 1,
        per_page: 24,
      });
    },
    exportData() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "order_list" + day + ".pdf";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    getSupplierCompanies() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/supplier-companies",
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.supplierCompanies = self.convertArrayToObjectByKey(
              result.data,
              "id",
              "name"
            );
            this.getTableItem();
          }
        });
    },
    onRowClicked(item, index, event) {
      if (this.isUserGranted("Order", ["create", "update", "view"])) {
        this.$router.push({
          name: "order.edit",
          params: {
            id: this.formattedItems[index].id,
            type: this.formattedItems[index].sample_id ? 2 : 1,
          },
        });
      }
      console.log("hit");
      return false;
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.toggleTheList = this.$route.params.extraParam;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("order.orders") },
    ]);

    this.config.content.width = "fluid";

    if (!this.filter) {
      this.resetFilters();
    } else {
      this.order_status_id = this.filters.order_status_id;
    }
    this.filters.start_date = localStorage.hasOwnProperty(
      ORDER_INDEX_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(ORDER_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);
    this.filters.end_date = localStorage.hasOwnProperty(ORDER_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(ORDER_INDEX_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    if (this.isUserGranted("Order", ["viewAny"])) {
      this.getSupplierCompanies();
      setTimeout(this.filterResult, 1000);
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    order_status_id() {
      this.filterResult();
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>

<style lang="scss">
/*Mobile Start*/
@import "../../../assets/sass/mobile/order/orderIndex.scss";
/*Mobile End*/
</style>
